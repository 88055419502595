import React from "react";
import HeadingAndByline from "../components/knowledgeCentre/HeadingAndByline";
import Webinars from "../components/knowledgeCentre/Webinars";
import KnowledgeCentrePage from "../templates/KnowledgeCentrePage";

const seo = {
  title: "Webinars",
  description:
    "Watch our online event series at Plastometrex, covering applications for PIP testing, recent researh studies and live demonstrations",
};

const title = `Webinars`;
const byline = `We run regular technical webinars with our collaboratoes and customers. Browse to watch our on-demand webinars or register for an upcoming live event.`;

function WebinarsPage() {
  return (
    <KnowledgeCentrePage seo={seo} linkPath="/webinars">
      <HeadingAndByline title={title} byline={byline} />
      <Webinars />
    </KnowledgeCentrePage>
  );
}

export default WebinarsPage;
