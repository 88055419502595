import React from "react";
import { styled } from "../../stitches/stitches.config";

const Webinars = () => {
  return (
    <Container>
      <iframe
        width="100%"
        height="200"
        frameBorder="0"
        src="https://app.livestorm.co/plastometrex/upcoming?limit=3"
        title="Plastometrex events | Livestorm"
      ></iframe>
      <iframe
        width="100%"
        height="200"
        frameBorder="0"
        src="https://app.livestorm.co/plastometrex/on_demand"
        title="Plastometrex events | Livestorm"
      ></iframe>
      <iframe
        width="100%"
        height="200"
        frameBorder="0"
        src="https://app.livestorm.co/plastometrex/past"
        title="Plastometrex events | Livestorm"
      ></iframe>
    </Container>
  );
};

export default Webinars;

const Container = styled("div", {
  width: "95%",
  margin: "auto",
  marginBottom: 50,
  "@md": {
    width: "100%",
  },
});
