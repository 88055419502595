import React from "react";
import { heading300, paragraphTextSmall } from "../../stitches/combinedStyles";
import { styled } from "../../stitches/stitches.config";

const HeadingAndByline = ({
  title,
  byline,
}: {
  title: string;
  byline: string;
}) => {
  return (
    <HeadingContainer>
      <Title>{title}</Title>
      <Byline>{byline}</Byline>
    </HeadingContainer>
  );
};

export default HeadingAndByline;

const HeadingContainer = styled("div", {
  display: "none",
  marginBottom: 32,
  "@lg": {
    display: "block",
  },
});

const Title = styled("h2", {
  ...heading300,
  margin: 0,
  "@md": {
    marginTop: 40,
  },
  "@lg": { margin: 0 },
});

const Byline = styled("p", {
  ...paragraphTextSmall,
  margin: 0,
});
